import { ErrorManager } from '@/Ship';

const { apiErrors } = ErrorManager;

let STACK = [];
let BUSY = false;

const resolveStack = () => {
    if (!BUSY && STACK.length) {
        BUSY = true;
        const task = STACK.shift();
        task();
    } else {
        BUSY = false;
    }
};

export default {
    push: (task) => {
        const request = new Promise((resolve, reject) => {
            return STACK.push(() => {
                task().then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                    apiErrors[error.response?.status]?.(error.response.data);
                }).finally(() => {
                    setTimeout(() => {
                        BUSY = false;
                        resolveStack();
                    }, 50);
                });
            });
        });

        resolveStack();

        return request;
    }
};
