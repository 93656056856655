import { STACK, useShipResources } from '@/Ship';
import axios from 'axios';

const { BaseResource } = useShipResources();

export default class Newsfeed extends BaseResource {

	static #instance = null;

	static getInstance() {
		if (!Newsfeed.#instance) {
			Newsfeed.#instance = new Newsfeed();
		}
		return Newsfeed.#instance;
	}

  load() {    
    this.loaded.value = false;
    this.loading.value = true;

    const apiKey = process.env.VUE_APP_NEWSFEED_API_KEY || 'demo';
    const url = `https://www.alphavantage.co/query?function=NEWS_SENTIMENT&tickers=COIN,CRYPTO:BTC,FOREX:USD&time_from=20220410T0130&limit=1000&apikey=${apiKey}`;

    return STACK.push(() => axios.get(url).then((response) => {
      this.collection.value = response.data || [];
      this.loaded.value = true;
      this.loading.value = false;
        
      return response;
    }))
  }
}
