
import { useChartActions } from '@chart';
import { useProfileActions } from '@profile';
import { useShipActions, useShipResources, STACK, ApiClient, WebSockets as Stream } from '@/Ship';

const { BaseResource } = useShipResources();
const getProfileResource = useProfileActions('getProfileResource');
const resolveChartStream = useChartActions('resolveChartStream');

const definePriceScale = useShipActions('definePriceScale');

export default class Bar extends BaseResource {
    static #instance = null;

    endpoint = 'klines';

    section = 'Trading';
    container = 'Chart';

    rules = {}
    subscribe = {}

    onload = [];

    constructor() {
        super();
        Bar.#instance = this;

        Bar.#instance.profile = getProfileResource();

        return Bar.#instance;
    }

    static getInstance() {
        return !Bar.#instance
            ? new Bar()
            : Bar.#instance;
    }

    update(params = {}) {
        params = Object.assign(params, this.filters);
        return STACK.push(() => ApiClient.get(this.endpoint, { params }))
    }

    load(params = null) {
        this.loaded.value = false;
        this.loading.value = true;

        params = params || this.filters;

        return STACK.push(() => ApiClient.get(this.endpoint, { params })).then(response => {
            this.loaded.value = true;
            this.loading.value = false;

            this.collection.value = response.data.map(([time, open, high, low, close, volume]) => {
                return {
                    time: Number(time),
                    open: Number(open),
                    high: Number(high),
                    low: Number(low),
                    close: Number(close),
                    volume: Number(volume),
                };
            });

            this.idx.value = this.collection.value.length - 1;

            const pricescale = definePriceScale(this.model.close);
            const multiplier = (parseFloat(this.profile.multipliers[params.symbol]) || 0) / 100;

            const price = Math.floor((this.model.close + (this.model.close * multiplier)) * pricescale) / pricescale;

            this.model.time = Date.now();
            this.model.price = price;
            this.model.high = price > this.model.high ? price : this.model.high;
            this.model.low = price < this.model.low ? price : this.model.high;

            this.initBar(this.model);

            this.onload.forEach(action => {
                action(this.model);
            });

            this.clearEntity();

            const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

            stream.subscribe({ chart: {
                base: params.base,
                counter: params.counter,
                market: params.market,
                interval: params.interval,
            }});

            stream.addHandlers({
                chart: resolveChartStream
            });
        });
    }

    refill(params = {}) {

        this.loading.value = true;

        params = Object.assign(params, this.filters);

        return STACK.push(() => ApiClient.get(this.endpoint, { params })).then(response => {
            this.loading.value = false;

            const index = response.data.findIndex(item => item[0] >= this.collection.value[0].time);
            if (index > -1) {
                response.data = response.data.slice(0, index);
            }

            if(response.data.length) {
                this.collection.value = response.data.map(([time, open, high, low, close, volume]) => {
                    return {
                        time: Number(time),
                        open: Number(open),
                        high: Number(high),
                        low: Number(low),
                        close: Number(close),
                        volume: Number(volume),
                    };
                }).concat(this.collection.value);

                this.idx.value = this.collection.value.length - 1;
            }
        });
    }

    initBar(quote) {
        this.symbol = quote.symbol;
        this.price = quote.price;
        this.high = quote.high;
        this.low = quote.low;
        this.open = quote.open;
        this.volume = quote.volume;
        this.volume_24 = quote.volume_24;
    }
}
