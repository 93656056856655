
import { useQuoteResources } from '@quotes';

export default function(reload = false) {
    const commodity = useQuoteResources('Commodity');
    if (reload || (!commodity.isLoaded && !commodity.isLoading)) {
        commodity.setIncludes(['asset']);
        commodity.load();
    }

    return commodity;
}
