<script setup>

import { ref, computed, onMounted, onUnmounted, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import _ from 'lodash';
import { STATE, WebSockets as Stream } from '@/Ship';
import { useQuoteActions, useQuoteComponents } from '@quotes';
import { useChartActions } from '@chart';

const props = defineProps(['symbol', 'sortable', 'search']);

const {
    sortBy,
    getMarketResource,
    getCommodityResource,
    resolveQuotesStream
} = useQuoteActions();

const getBarResource = useChartActions('getBarResource');

const Ticker = useQuoteComponents('Ticker');

const markets = getMarketResource();
const resource = getCommodityResource();

const bar = getBarResource();

const tickers = computed(() => {
    const clonedQuotes = _.cloneDeep(resource.state.filter(item => {
        const searchFilter = props.search
            ? item.symbol.base.includes(props.search.toUpperCase())
            : true;

        return (
            searchFilter &&
            item.symbol !== props.symbol
        );
    }));

    // Sorting
    clonedQuotes.sort(
        sortBy(props.sortable || 'price')
    );

    return ref(clonedQuotes).value;
});

const router = useRouter();

function chooseQuote({ currentTarget: { dataset: { symbol } } }) {
    bar.state = [];
    bar.loaded.value = false;

    STATE.MARKET = 'commodities';
    STATE.SYMBOL = symbol;
    resource.choose(symbol);
    markets.choose('commodities');

    router.push({ params: {
        market: 'commodities',
        base: resource.model.base.toLowerCase(),
        counter: resource.model.counter.toLowerCase(),
    } });
}

/** Stream **/
const stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

onMounted(() => {
    stream.subscribe({ quotes: { market: 'commodities' }});
    stream.addHandlers({
        commodities: resolveQuotesStream,
    });
});

onUnmounted(() => {
    stream.unsubscribe('quotes');
});
</script>

<template>
    <!-- Favorite Tickers -->
    <!--
    <ticker v-for="ticker in tickers.filter(item => item.isFavorite)"
            v-bind:key="ticker.symbol"
            v-bind:ticker="ticker"
            v-bind:data-symbol="ticker.symbol"
            v-bind:data-market="ticker.market"
            v-on:click="chooseQuote"/>
    -->

    <!-- Other Tickers -->
    <ticker v-for="ticker in tickers"
            v-bind:key="ticker.name"
            v-bind:ticker="ticker"
            v-bind:data-symbol="ticker.symbol"
            v-on:click="chooseQuote"/>
</template>
