<script setup>
import { computed, onMounted } from 'vue';
import moment from 'moment';
import { useNewsfeedResources } from '@/Containers/Trading/Newsfeed';

const newsfeed = useNewsfeedResources('Newsfeed');

const feed = computed(() => newsfeed.state.feed || []);

onMounted(() => {
    newsfeed.load()
});

</script>

<template>
    <div class="newsfeed" ref="element">
        <h2>Trading News</h2>
        <ul v-if="feed.length > 0" class="newsfeed-list">
            <li class="newsfeed-item" v-for="item in feed" v-bind:key="item.title">
                <h3 v-bind:title="item.title">{{ item.title }}</h3>
                <div class="subttl">{{ item.source }} - {{ moment(item.time_published).format('MM-DD-YYYY') }}</div>
                <div class="summary">{{ item.summary }}</div>
                <a v-bind:href="item.url" target="_blank">Read more</a>
            </li>
        </ul>
        <div v-else-if="newsfeed.state.Information">
            {{ newsfeed.state.Information }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.newsfeed {
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

.newsfeed-list {
    display: flex;
    column-gap: 16px;
    overflow: auto;
    padding-bottom: 16px;
}

.newsfeed-item {
    display: flex;
    flex-direction: column;
    width: 280px;
    flex-shrink: 0;

    > h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0;
        margin-bottom: 16px;
    }

    > .subttl {
        margin-bottom: 8px;
    }

    > .summary {
        margin-bottom: 16px;
    }

    > a {
        margin-top: auto;
        color: inherit;
        text-decoration: underline;
    }
}

@media (min-width: 985px) {
    .newsfeed {
        padding: 0;
    }
}
</style>
