
import { useQuoteActions } from '@quotes';
import {useShipResources, STACK, ApiClient, STATE} from '@/Ship';
import { useProfileActions } from '@profile';

const { BaseResource } = useShipResources();
const getProfileResource = useProfileActions('getProfileResource');
const quoteNormalization = useQuoteActions('quoteNormalization');

export default class Commodity extends BaseResource {
	
	static #instance = null;
	
	endpoint = '/quotes';
	
	section = 'Trading';
	container = 'Quotes';
	resource = 'Commodities';

	symbol = 'XAUUSD';
	currency = 'USD';
	
	constructor() {
		super();
		if (!Commodity.#instance) {
			Commodity.#instance = this;
			Commodity.#instance.profile = getProfileResource();
		}
		return Commodity.#instance;
	}
	
	static getInstance() {
		return new Commodity();
	}
	
	load() {
		this.loaded.value = false;
		this.loading.value = true;
		
		return STACK.push(() => ApiClient.get(this.endpoint, { params: {
			market: 'commodities',
			include: 'asset',
		}})).then(response => {
			
			const payload = response.data || [];
			this.collection.value = payload.map(quote => {
				const multiplier = (parseFloat(this.profile.multipliers[quote.symbol]) || 0) / 100;
				Object.assign(quote, quoteNormalization(quote, multiplier));
				
				return quote;
			});
			
			if (payload.length) {
				this.choose(this.symbol);
			}
			
			this.loaded.value = true;
			this.loading.value = false;

			if (Array.isArray(this.onload)) {
				this.onload.forEach(task => {
					task(response);
				});
			} else if (typeof this.onload === 'function') {
				this.onload();
			}

			return payload;
		});
	}

	find(base) {
		return this.collection.value.find(ticker => ticker.base === base);
	}

	choose(symbol) {
		symbol = symbol.toUpperCase();

		const index = this.collection.value.findIndex(ticker => ticker.symbol === symbol);

		if (index >= 0) {
			this.idx.value = index;
			this.symbol = symbol;
			STATE.SYMBOL = symbol;
		}
	}
}
